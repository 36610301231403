import { Navigate, Outlet, useLocation, useOutlet } from 'react-router-dom'
import { ROUTE_APPLICATION_RESULT, ROUTE_HOME, ROUTE_SIGN_IN } from './routes/paths.js'
import React, { useEffect } from 'react'

import PrivateLayout from '@/components/layout/private-layout.jsx'
import { Toaster } from '@/components/ui/sonner'
import { useAuth } from '@/contexts/auth.context'

export default function App() {
  const { authenticated, isTokenRefreshing } = useAuth()
  const { pathname } = useLocation()
  const outletContent = useOutlet()

  useEffect(() => {
    if (!isTokenRefreshing) {
      console.log('Token refresh completed, updating UI...')
    }
  }, [isTokenRefreshing])

  if (!authenticated) {
    return <Navigate to={ROUTE_SIGN_IN} replace />
  } else {
    if (!outletContent) {
      return <Navigate to={ROUTE_HOME} replace />
    } else {
      if (isTokenRefreshing) {
        return <div>Token refreshing...</div>
      }
      const Layout = pathname === ROUTE_APPLICATION_RESULT ? React.Fragment : PrivateLayout
      return (
        <>
          <Layout>
            <Outlet />
            <Toaster position="bottom-left" />
          </Layout>
        </>
      )
    }
  }
}
